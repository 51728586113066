class JournalAuth {
  #ID = 'id'
  #TOKEN = 'token'
  #PROFILEID = 'profileId'
  login(id, token, profileId){
    localStorage.setItem(this.#ID, id)
    localStorage.setItem(this.#TOKEN, token)
    localStorage.setItem(this.#PROFILEID, profileId)
  }
  logout(){
    localStorage.removeItem(this.#ID)
    localStorage.removeItem(this.#TOKEN)
    localStorage.removeItem(this.#PROFILEID)
  }
  getProfileId(){
    return localStorage.getItem(this.#PROFILEID)
  }
  isLoggedIn(){
    try {
      return localStorage.getItem(this.#TOKEN).match(/[0-9a-f]*/)[0] ==
        localStorage.getItem(this.#TOKEN) &&
        localStorage.getItem(this.#ID) != null
    } catch(e) {
      if(e instanceof TypeError){
        // ignore
      } else {
        console.log(e)
      }
    }
    return false
  }
}

const journalAuth = new JournalAuth

export default journalAuth
