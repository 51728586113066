<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
         <header class="container-xl d-flex flex-wrap justify-content-between py-1">

            <router-link to="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                    <span class="fs-4">Journal</span>
                </router-link>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 nav-pills">
                    <li class="nav-item"><router-link class="nav-link" to="/">Activities</router-link></li>
                    <!--
                    <li class="nav-item"><router-link class="nav-link" to="/goals">Goals</router-link></li>
                    -->
                    <li class="nav-item"><router-link class="nav-link" to="/plan">My Plan</router-link></li>
                    <!--
                    <li class="nav-item"><router-link class="nav-link" to="/admin">Admin</router-link></li>
                    -->
                    <li class="nav-item"><router-link class="nav-link" to="/logout">Logout</router-link></li>
                </ul>

            </div>
        </header>
    </nav>
</template>

<script>
export default {
    name: 'MainNav',
    props: {}
}

/* https://stackoverflow.com/questions/46083220/how-to-vuejs-router-link-active-style */

</script>
