<template>
  <section id="dashboard" class="my-4 container-xl">
    <div v-if="loading">loading</div>
    <div v-else-if="result && result.taskGroups">
      <h1>My Activities</h1>
      <hr class="mb-2" />
      <div class="d-flex flex-wrap justify-content-start" v-if="result.taskGroups" style="display:grid;">
        <TaskGroup v-for="taskGroup of result.taskGroups" :key="taskGroup.id" :taskGroup="taskGroup" :groups="result.taskGroups"/>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import TaskGroup from '@/components/TaskGroup.vue'
import { useQuery } from '@vue/apollo-composable'
import { gql } from 'graphql-tag'
import journalAuth from '@/auth'

const filters = {
  new(tasks) {return tasks.filter(task => task.status=='new')},
  complete(tasks) {return tasks.filter(task => task.status=='complete')},
  inProgress(tasks) {return tasks.filter(task => !['new','complete'].includes(task.status))},
};

export default {
  name: 'HomeView',
  components: {
    TaskGroup
  },
  setup(/*props, context*/){
    const { result, loading } = useQuery(gql`
      query ListTasks($profileId:ID!){
        taskGroups(profileId:$profileId){
          id,
          title,
          tasks{
            id,
            module{id,name,config},
            status
          }
        }
      }
      `,
      {profileId: journalAuth.getProfileId()},
      {fetchPolicy: 'no-cache'},
    )
    return {
      result,
      loading,
      filters,
    }
  }
}
</script>
