import { createApp, provide, h } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { ApolloLink, concat } from "apollo-link";
import { HttpLink } from 'apollo-link-http';
import { DefaultApolloClient } from '@vue/apollo-composable'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueApexCharts from "vue3-apexcharts";
import 'mdb-vue-ui-kit/css/mdb.min.css';

const httpLink = new HttpLink({ uri: process.env.VUE_APP_API_TARGET });
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext({
    headers:{
      authorization: token ? `Bearer ${token}`:'',
    },
  });
  return forward(operation);
});
const defaultClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
})

createApp({
  setup(){
    provide(DefaultApolloClient, defaultClient)
  },
  render(){
    return h(App)
  }
}).use(router).use(VueApexCharts).mount('#app')
