<template>
  <div class="p-3">
    <ul class="admin-nav p-0">
        <li class="d-flex justify-content-start p-2">
            <i class="bi bi-speedometer2"></i>
            <router-link to="/admin">Dashboard</router-link>
        </li>
        <li class="d-flex justify-content-start p-2">
            <i class="bi bi-megaphone-fill"></i>
            <router-link to="/admin/engagement">Engagement</router-link> <!-- reach? -->
        </li>
        <li class="d-flex justify-content-start p-2">
            <i class="bi bi-people-fill"></i>
            <router-link to="/admin/population">Population</router-link>
        </li>
        <li class="d-flex justify-content-start p-2">
            <i class="bi bi-gear-fill"></i>
            <router-link to="/admin/settings">Settings</router-link>
        </li>
    </ul>
  </div>
</template>

<script>

export default {
    name: 'AdminNav',
    components: {
       
    }
}
</script>
