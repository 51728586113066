import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AdminView from '@/views/AdminView.vue'
import AdminHome from '@/components/AdminHome.vue'
import journalAuth from '@/auth'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    children: [
      {
        path: '', component: AdminHome
      },
      {
        path: 'engagement',
        component: () => import(/* webpackChunkName: "Admin Engagement" */ '@/components/AdminEngagement.vue')
      },
      {
        path: 'population',
        component: () => import(/* webpackChunkName: "Admin Population" */ '@/components/AdminPopulation.vue')
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "Admin Settings" */ '@/components/AdminSettings.vue')
      }
    ]
  },
  {
    path: '/enroll/:code?',
    name: 'enroll',
    component: () => import(/* webpackChunkName: "enroll" */ '@/views/EnrollView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
  },
  {
    path: '/goals',
    name: 'goals',
    component: () => import(/* webpackChunkName: "goal" */ '@/views/GoalView.vue')
  },
  {
    path: '/plan',
    name: 'plan',

    component: () => import(/* webpackChunkName: "offline" */ '@/views/PlanningView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "landing" */ '@/views/LandingView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "landing" */ '@/views/LandingView.vue')
  },
  /*
  {
    path: '/modules/:id',
    name: 'modules',
    component: () => import('@/components/ModuleDetail.vue')
  },*/
  {
    path: '/task/:id',
    name: 'task',
    component: () => import(/* webpackChunkName: "taskdetail" */ '@/components/TaskDetail.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const anonymousPaths = [
  'login',
  'logout',
  'enroll',
]

router.beforeEach(async (to /*, from */) => {
  // If not logged in and trying to access a path that requires login, go to login screen.
  if(!journalAuth.isLoggedIn() && !anonymousPaths.includes(to.name)){
    return {name: 'login'}
  }
  // If logged in, go to home instead of login screen.
  if(to.name == 'login'){
    if(journalAuth.isLoggedIn()){
      return {name: 'home'}
    }
  }
  // If logging out, clear local data.
  if(to.name == 'logout'){
    journalAuth.logout()
  }
})

export default router
