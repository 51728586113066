<template>
  <section>
    Admin Home
  </section>
</template>

<script>

export default {
    name: 'AdminHome',
    components: {
       
    }
}
</script>
