<template>
    <div class="module m-3" :class=moduleMode>
        <div class="module-background">
            <div class="badge">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
</svg>
            </div>
            <router-link :to="{ name:'task', params: {id: task.id}}" custom v-slot="{ navigate }">
                <div class="module-body" @click="navigate" :style="{background: moduleColor, 'pointer-events': (prerequisitesComplete?'auto':'none')}">
                    <img :src="require('@/assets/icons/' + iconUrl)" />
                </div>
            </router-link>
        </div>

        <div class="module-title">
            <h5 >{{ task.module.name }}</h5>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TaskOverview',
    props: {
        task: Object,
        prerequisitesComplete: Boolean,
    },
    setup(props) {
        const iconUrl = JSON.parse(props.task.module.config).icon;
        let moduleMode = '';
        let moduleColor = '';

        switch(props.task.status) {
            case 'new':
                // in the css, new is the base class, so we just pop on out of here
                break;
            case 'complete':
                moduleMode = 'complete';
                moduleColor = JSON.parse(props.task.module.config).color;
                break;
            default:
                // any of the intermediate steps
                moduleMode = 'in-progress';
                moduleColor = JSON.parse(props.task.module.config).color;
                break;
        }

        return {
          iconUrl,
          moduleMode,
          moduleColor,
      }
    }
}
</script>
