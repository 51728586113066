<template>
    <div id="journal-app">
      <main-nav />
      <router-view />
    </div>
</template>

<script>
import MainNav from '@/components/MainNav.vue';

/* TODO: check if logged in so we can put v-ifs around the main-nav components, etc */

export default {
  components: { MainNav }
}
</script>
