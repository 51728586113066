<template>
  <div>
    <h5 v-if="taskGroup.title"><b>{{taskGroup.title}}</b></h5>
    <div :class="`${prerequisites_complete ? 'journal-task-group' : 'journal-task-group disabled'}`" class="p-3 mb-4">
      <div>
        <div class="d-flex flex-wrap justify-content-around" v-if="taskGroup.tasks">
          <TaskOverview v-for="task of taskGroup.tasks" :key="task.id" :task="task" :prerequisitesComplete="prerequisites_complete" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskOverview from '@/components/TaskOverview.vue'

export default{
  name: 'TaskGroup',
  components: { TaskOverview },
  props: {
    taskGroup: Object, // current task group
    groups: Array,     // all task groups
  },
  setup(props/*, context*/){
    // Prerequisites groups are any groups before this group.
    const prerequisites = props.groups.slice(
      0,
      props.groups.map(
        taskGroup => taskGroup.id == props.taskGroup.id
      ).indexOf(true)
    )
    // Prerequisites are complete if all tasks in all prior groups are complete.
    const prerequisites_complete = prerequisites.reduce(
      (previous, current) => previous && current.tasks.reduce(
        (previous, current) => previous && current.status == 'complete',
        true
      ),
      true
    )
    //console.log(`props.taskGroup.id ${props.taskGroup.id} prerequisites_complete ${prerequisites_complete}`)//debug
    return {
      prerequisites_complete
    }
  }
}
</script>
