<template>
  <section id="admin" class="wrapper">
    <div class="row gx-0">
      <div class="col-md-2">
        <AdminNav />
      </div>
      <div class="col p-3">
        <!-- different admin views load here -->
        <router-view />
      </div>
    </div>
  </section>
</template>

<script>
import AdminNav from '@/components/AdminNav.vue'

export default {
    name: 'AdminView',
    components: {
       AdminNav
    }
}
</script>
